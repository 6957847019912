import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Articles from '../../components/Articles';

export default class Denmark extends Component {
  render() {
    const { data } = this.props;
    const posts = get(data, 'allMarkdownRemark.edges', []);

    return <Articles posts={posts} />;
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { country: { eq: "DK" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            date(formatString: "MMMM DD, YYYY")
            templateKey
            banner {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            authorPicture {
              childImageSharp {
                fixed(width: 39, height: 39) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
